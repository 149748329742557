import React from 'react'
import {Link} from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-gtag'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import TocList from '../../../../../components/Software/Windows/InstarVision/Windows_Phone/TocList-Software-InstarVision-WP'
import QRCode from '../../../../../components/Software/Windows/InstarVision/Windows_Phone/TocList-Software-InstarVision-WP-QR'

import NavButtons from '../../../../../components/Software/Windows/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'InstarVision for Windows Phone',
  description: 'The Windows app InstarVision for your Windows Phone. Control your camera when you don´t have access to your PC.',
  image: '/images/Search/P_SearchThumb_InstarVision_WP.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_WP.webp',
  locationEN: '/en/Software/Windows/InstarVision/Windows_Phone/',
  locationDE: '/de/Software/Windows/InstarVision/Windows_Phone/',
  locationFR: '/fr/Software/Windows/InstarVision/Windows_Phone/'
}


function IVAndroid(props) {
    return (
                
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="InstarVision Windows Phone" />
          
      <NavButtons />

      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Windows Phone Software</h2>
      </EuiTitle>
      <EuiText>
        <p>To access your IP camera with our Windows Phone App InstarVision we recommend that you first download the newest version from the <OutboundLink href="https://www.microsoft.com/de-de/store/p/instarvision/9nblggh10wtp" target="_blank" rel="noopener noreferrer">Microsoft Store</OutboundLink>. Use the search word Instar to find our app inside the store.</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiTitle size="m">
        <h3>Overview over App Menus</h3>
      </EuiTitle>
      <TocList />
      <EuiSpacer size="xl" />
      <EuiTitle size="m">
        <h3>Download</h3>
      </EuiTitle>
      <EuiSpacer size="l" />
      <QRCode />
      <EuiSpacer size="Xl" />
      <p><em>List of <Link to="/Software/Android/InstarVision/Thirdparty_Cameras/">compatible thirdparty cameras</Link></em></p>
    </React.Fragment>
  )
}

export default IVAndroid