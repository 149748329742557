import React, {Fragment} from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem
} from '@elastic/eui'

function TOCList() {

  return (
    <Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/DDNS/">
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Windows_Phone/Icons/QL-Box_iOS_InstarVision_DDNS.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="DDNS Remote Access"
              description="The DDNS Access gives you full access to all your cameras functions. As well as allowing you to access its web user interface through your browser ..."
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/P2P/">
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Windows_Phone/Icons/QL-Box_iOS_InstarVision_P2P.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="P2P Remote Access"
              description="The P2P Access does not give you full access to all your cameras functions. But you don´t need to forward a port inside your router and P2P also works ..."
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/Multiview/">
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Windows_Phone/Icons/QL-Box_iOS_InstarVision_Multiview.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Camera Multiview"
              description="Behalten Sie alle Ihre Kameras im Multiview-Menü im Blick. Klicken Sie auf eine beliebige Kamera, um auf deren Video-Livestream zuzugreifen und sie über Funktionstasten zu steuern. more..."
            />
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    <EuiSpacer />
    
    <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" style={{margin: '0 7px'}} >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/Recording/">
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Windows_Phone/Icons/QL-Box_iOS_InstarVision_Recording.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="Video Recordings"
              description="Videos recorded to your smartphones memory and videos on your cameras internal SD Card can be accessed through the Recording Menu ..."
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <Link to="/Software/Windows/InstarVision/Windows_Phone/Settings/">
                  <StaticImage
                    src='../../../../../images/Software/Windows/InstarVision/Windows_Phone/Icons/QL-Box_iOS_InstarVision_Settings.webp'
                    alt="InstarVision 2.0"
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                    />
                </Link>
              }
              title="General App Settings"
              description="Adjust your apps global Settings for your app and all your cameras, create Backups and access the in-App Help Section with explanations for all function buttons ..."
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
      </EuiFlexGroup>
    </EuiFlexItem>

    </Fragment>

  );
}

export default TOCList